.DocumentMetadataCard-doc-icon {
  color: var(--chakra-colors-purple-500);
  font-size: var(--chakra-fontSizes-5xl);
}

.chakra-ui-dark .DocumentMetadataCard-doc-icon {
  color: var(--chakra-colors-purple-300);
}

.DocumentMetadataCard-search-result-match {
  color: var(--chakra-colors-gray-500);
  font-size: var(--chakra-fontSizes-sm);
}

.chakra-ui-dark .DocumentMetadataCard-search-result-match {
  color: var(--chakra-colors-gray-300);
}

.SpeakerList-role {
  text-transform: capitalize;
}

.SpeakerLink-affiliation-link-icon {
  display: inline-block;
  font-size: 12px;
  margin: 0 0 0 4px;
}
