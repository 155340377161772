.Page {
  padding: var(--app-header-height) 0 0;
}

.Page .Page-loader {
  position: fixed;
  left: 0;
  right: 0;
  top: var(--app-header-height);
}

.Page-inner {
  max-width: var(--app-max-width);
  padding: var(--chakra-space-8) var(--chakra-space-6);
}

.Page-inner.no-pad {
  padding: 0;
}
