.Header {
  background-color: var(--chakra-colors-chakra-body-bg);
  height: var(--app-header-height);
  left: 0;
  padding: var(--chakra-space-4) var(--chakra-space-6);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.Header-inner {
  margin: 0 auto;
  max-width: var(--app-max-width);
}

.chakra-ui-light .Header-nav-link {
  color: var(--chakra-colors-gray-700);
}

.chakra-ui-light .Header-nav-link:hover,
.chakra-ui-light .Header-nav-link.active {
  color: var(--chakra-colors-gray-900);
}

.chakra-ui-dark .Header-nav-link {
  color: var(--chakra-colors-gray-300);
}

.chakra-ui-dark .Header-nav-link:hover,
.chakra-ui-dark .Header-nav-link.active {
  color: var(--chakra-colors-white);
}

.Header-nav-link.active {
  font-weight: 600;
}
