.MultiSelect .MultiSelect-control {
  border-color: var(--chakra-colors-chakra-border-color);
  background: none;
  box-shadow: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
}

.MultiSelect .MultiSelect-control:hover {
  border-color: var(--chakra-colors-gray-300);
}

.chakra-ui-dark .MultiSelect .MultiSelect-control:hover {
  border-color: var(--chakra-colors-whiteAlpha-400);
}

.MultiSelect .MultiSelect-control.MultiSelect-control-focus {
  border-color: var(--chakra-colors-blue-500);
  box-shadow: 0 0 0 1px var(--chakra-colors-blue-500);
}

.chakra-ui-dark .MultiSelect .MultiSelect-control.MultiSelect-control-focus {
  border-color: var(--chakra-colors-blue-300);
  box-shadow: 0 0 0 1px var(--chakra-colors-blue-300);
}

.MultiSelect .MultiSelect-input {
  color: var(--chakra-colors-chakra-body-text);
}

.chakra-ui-dark .MultiSelect .MultiSelect-menu {
  background-color: var(--chakra-colors-gray-900);
}

.MultiSelect .MultiSelect-option-focus {
  background-color: var(--chakra-colors-blue-50);
}

.chakra-ui-dark .MultiSelect .MultiSelect-option-focus {
  background-color: var(--chakra-colors-gray-700);
}

.MultiSelect .MultiSelect-multi-value {
  background-color: var(--chakra-colors-gray-200);
}

.MultiSelect .MultiSelect-multi-value-label {
  color: var(--chakra-colors-gray-900);
}

.MultiSelect .MultiSelect-multi-value-remove:hover {
  background-color: var(--chakra-colors-red-300);
  color: var(--chakra-colors-red-800);
}
