.chakra-ui-light .Link a {
  color: var(--chakra-colors-purple-700);
}

.chakra-ui-dark .Link a {
  color: var(--chakra-colors-purple-300);
}

.chakra-ui-light .Link.secondary a {
  color: var(--chakra-colors-gray-500);
}

.chakra-ui-dark .Link.secondary a {
  color: var(--chakra-colors-gray-300);
}
