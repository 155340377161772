.chakra-ui-light .DocumentsPage-doc-link:hover {
  box-shadow: var(--chakra-shadows-md);
}

.chakra-ui-dark .DocumentsPage-doc-link:hover {
  outline: solid 3px var(--chakra-colors-purple-400);
  border-radius: 6px;
}

.DocumentsPage-doc-link:hover .Link {
  text-decoration: none;
}

.DocumentsPage-no-docs-message,
.Pagination-results-tracker {
  color: var(--chakra-colors-gray-400);
}
