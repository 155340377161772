.Alert {
  border-radius: var(--chakra-radii-base);
}

.Alert-icon {
  font-size: var(--chakra-fontSizes-2xl);
}

.chakra-ui-light .Alert-info {
  background-color: var(--chakra-colors-blue-50);
}
