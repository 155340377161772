.DocumentPage .DocumentMetadataCard {
  margin: 0 0 var(--chakra-space-16);
}

.SpeakerMeta-role {
  text-transform: capitalize;
}

.SpeakerMeta-link {
  display: inline-block;
}
